@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url("https://use.typekit.net/gjn2rvg.css");

@import "./vars";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-family: $font-main;
    font-size: 16px;
    line-height: 1.4;

    @media (min-width: 1024px) {
        font-size: 21px;
    }
}

body {
    background: rgba(55, 114, 39, 1) url("./images/bg_drewno_3.jpg") no-repeat fixed 0px 0px / cover;
    color: $color-main;
    margin-top: 60px;

    @media (min-width: 1024px) {
        margin-top: 0;
    }

    #root {
        @media (min-width: 1024px) {
            display: flex;
            flex-flow: row nowrap;
        }
    }

    .sections {
        @media (min-width: 1024px) {
            width: calc(100% - 350px);
            left: 350px;
            position: relative;
        }
    }
}

.icons-row {
    display: flex;
    justify-content: center;

    > div {
        position: relative;
        padding: 0px 20px;

        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0px;
            top: 0px;
            //background: $color-main;
        }

        &:last-child:after {
            display: none;
        }
    }
}

.slider-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > div {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .slider-row {
        > div {
            width: 50%;
        }
    }
}

.shops {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0px 20px;

    img {
        border-radius: 10px;
        width: 100px;
        height: auto;
    }
}

@media (min-width: 1200px) {
    .shops {
        img {
            width: 150px;
        }
    }
}

@media (min-width: 1500px) {
    .shops {
        img {
            width: 200px;
        }
    }
}

.o-tuli {
    width: 100%;
    max-width: 1180px;
    margin: 0px auto;

    b {
        color: $color-second;
    }

    img {
        border-radius: 10px;
    }

    .text-big {
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {
    .o-tuli {
        display: flex;

        &__inner-1,
        &__inner-2 {
            width: 50%;
            padding-top: 40px;
        }

        &__inner-1 {

        }

        &__inner-2 {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

.karmy {
    width: 100%;
    max-width: 1180px;
    margin: 0px auto;
    text-align: center;

    b {
        color: $color-second;
    }

    img {
        width: 100%;
        max-width: 900px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .text-big {
        font-size: 1rem;
    }
}

@media (min-width: 1024px) {
    .karmy {
        .text {
            max-width: 620px;
        }
    }
}

a[data-inactive-label] {
    font-weight: 600;
}

#WhereSection {
    .image {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
}

#MainSection {
    .header {
        z-index: 2;
    }

    .button {
        position: absolute;
        white-space: nowrap;
        left: 50%;
        bottom: 24vw;
        transform: translate(-50%, 0%);

        @media (min-width: 600px) {
            bottom: 10vw;
        }
        @media (min-width: 768px) {
            bottom: 8vw;
        }
        @media (min-width: 1024px) {
            bottom: 4vw;
        }
        @media (min-width: 1200px) {
            bottom: 6vw;
        }
        @media (min-width: 1920px) {
            bottom: 116px;
        }

    }

    .image-wrapper {
        position: relative;
    }

    .zdjecie-symboliczne {
        font-size: 12px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        bottom: 30vw;
        transform: translate(-50%, 0%);

        @media (min-width: 600px) {
            bottom: 12vw;
        }
        @media (min-width: 768px) {
            bottom: 10vw;
        }
        @media (min-width: 1024px) {
            bottom: 6vw;
        }
        @media (min-width: 1200px) {
            bottom: calc(-20px + 3vw);
            margin-left: -18vw;
        }
        @media (min-width: 1920px) {
            bottom: 38px;
            margin-left: -346px;
        }
    }
}

#FoodSection {
    .image-wrapper {
        position: relative;
        padding-bottom: 30px;
    }

    .zdjecie-symboliczne {
        font-size: 12px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        bottom: calc(-10px + 10vw);
        transform: translate(-50%, 0%);

        @media (min-width: 600px) {
            bottom: calc(-10px + 8vw);
        }
        @media (min-width: 768px) {
            bottom: calc(-10px + 7vw);
        }
        @media (min-width: 1024px) {
            bottom: calc(-10px + 6vw);
        }
        @media (min-width: 1200px) {
            bottom: 60px;
            margin-left: -18vw;
        }
        @media (min-width: 1250px) {
            bottom: 60px;
            margin-left: -225px;
        }
    }
}
