@import "../../vars";

.carousel {
    margin-top: 20px;
    max-width: 400px;
    &.carousel-slider {
        @media (min-width: 1024px) {
            overflow: visible;
        }
    }
    .slide img {
        max-width: 220px;
        @media (min-width: 1024px) {
            max-width: 400px;
        }
    }

    .control-dots {
        display: none;
    }

    .control-arrow {
        opacity: 1;
    }

    .control-next.control-arrow.control-disabled,
    .control-prev.control-arrow.control-disabled {
        display: block;
        opacity: 0.6;
    }

    .thumbs-wrapper.axis-vertical {
        .control-arrow.control-prev,
        .control-arrow.control-next {
            display: none;
        }
    }

    .control-arrow:hover {
        background-color: transparent;
    }

    .control-prev.control-arrow {
        left: 15px;
        @media (min-width: 1024px) {
            left: -60px;
        }
    }

    .control-prev.control-arrow:before {
        border: none;
        background: url("./images/arrow-left.png") center center no-repeat;
        width: 31px;
        height: 31px;
    }

    .control-next.control-arrow {
        right: 15px;
        @media (min-width: 1024px) {
            right: -60px;
        }
    }

    .control-next.control-arrow:before {
        border: none;
        background: url("./images/arrow-right.png") center center no-repeat;
        width: 31px;
        height: 31px;
    }

    .thumbs {
        &:before {
            content: '';
            background-color: rgba(255, 255, 255, 0.6);
            height: 1px;
            position: absolute;
            top: 0;
            width: calc(100% - 40px);
            left: 50%;
            transform: translateX(-50%);
            @media (min-width: 1024px) {
                width: 100%;
                left: initial;
                transform: none;
            }
        }

        position: relative;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow: row nowrap;
    }

    .thumb {
        border: none;
        margin-right: 0;

        &.selected {
            border: 2px solid $color-main;
            border-radius: 15px;
        }
    }
}
