@import "../../vars";

.text {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    @media (min-width: 1024px) {
        //max-width: 450px;
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }

    &--hidden {
        display: none;
    }

    p {
        text-align: center;
        font-size: 0.75rem;
        padding: 0 20px;
    }

    .text-big {
        font-weight: 400;
        font-family: $font-second;
        text-transform: uppercase;
    }
    a {
        cursor: pointer;
        font-size: 14px;
        font-family: $font-main;
        font-weight: bold;
        text-decoration: underline;
    }
    .toggle {
        display: none;
        &.active {
            display: block;
        }
    }
}
