@import "../../vars";

.header {
    width: 100%;
    display: block;
    text-align: center;
    font-family: $font-second;
    text-transform: uppercase;
    font-weight: 400;

    &.--green {
        color: $color-green;
    }

    h1 {
        font-size: 1.5rem;
        line-height: 2.5rem;
        padding: 0 50px;
        font-weight: 400;
    }

    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        padding: 0 50px;
        font-weight: 400;
    }

    h3 {
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding: 0 20px;
        font-weight: 400;
    }
}
