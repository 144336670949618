@import "../../vars";

.tabs {
    @import "./Tabs.overwrite.scss";
    width: 100%;
    max-width: 400px;
    @media (min-width: 1024px) {
        max-width: 100%;
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        list-style-type: none;
        padding: 0 20px;
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
        padding-bottom: 40px;

        li {
            padding: 0 10px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 25px;
            border: 2px solid transparent;
            font-size: 0.625rem;
            pointer-events: auto;
            position: relative;
            z-index: 2;
            cursor: pointer;

            &.active {
                border-radius: 15px;
                border: 2px solid $color-main;
                pointer-events: none;
                z-index: 1;
            }
        }
    }

    &__screens {
        overflow-x: hidden;
        position: relative;

        .tab {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            &.active {
            }

            .header {
                margin-bottom: 20px;
            }
        }
    }
}
