@import "./Screen.overwrite";

.screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    overflow-x: hidden;
    row-gap: 20px;
    padding: 50px 0;

    @media (min-width: 1024px) {
        //min-height: 100vh;
        justify-content: flex-start;
    }

    .content {
        //@extend .screen;
        width: 100%;

        @media (min-width: 1024px) {
            //flex-flow: row nowrap;
            //height: auto;
        }
    }
}
