@import "../../vars";

.hamburger {
    $root: &;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $color-main;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 20px;
        background: $color-main;
        opacity: 1;
        left: calc(50% - 10px);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    span:nth-child(1) {
        top: 9px;
    }

    span:nth-child(2), span:nth-child(3) {
        top: 17px;
    }

    span:nth-child(4) {
        top: 25px;
    }

    &--open {
        span:nth-child(1) {
            top: 9px;
            width: 0;
            left: 50%;
        }

        span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            top: 9px;
            width: 0;
            left: 50%;
        }
    }
}
