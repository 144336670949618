@import "../../vars";

.menu {
    $root: &;

    width: 100%;
    height: 60px;
    background: rgba(55, 114, 39, 1) url("../../images/bg_drewno_3.jpg") no-repeat fixed 50% 0px / cover;
    position: fixed;
    top: 0;
    z-index: 20;
    box-shadow: 0px 0px 40px 10px #000;
    transition: height 300ms ease-in-out;

    &--open {
        height: 100vh;

        #{$root}__list {
            opacity: 1;
            height: auto;
        }

        #{$root}__hamburger {
            &--top {
                opacity: 0;
            }

            &--bottom {
                height: 40px;
                opacity: 1;
            }
        }
    }

    &__logo {
        width: 100px;
        justify-self: end;
        height: 40px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__list {
        opacity: 0;
        height: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-self: center;
        flex-flow: column nowrap;
        row-gap: 15px;
        list-style-type: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .25s ease-in-out;
    }

    &__element {
        font-size: 0.875rem;
        text-transform: uppercase;
        color: $color-main;
        text-decoration: none;
        letter-spacing: 1px;
        cursor: pointer;
    }

    &__hamburger {
        position: absolute;

        &--top {
            opacity: 1;
            top: 10px;
            right: 10px;
            transition: .25s ease-in-out;
        }

        &--bottom {
            height: 0;
            opacity: 0;
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
        }
    }
}

@media (min-width: 1024px) {
    .menu {
        $root: &;

        top: 0;
        height: 100vh;
        width: 350px;
        transition: none;

        #{$root}__list {
            opacity: 1;
            height: auto;
        }

        #{$root}__hamburger {
            &--top,
            &--bottom {
                display: none;
            }
        }

        &__logo {
            width: 220px;
            height: auto;
            top: 40px;
        }

        &__list {
            //@media (max-height: 700px) {
            //    top: calc(50% + 80px);
            //}

            padding-top: 130px;

            li {
                width: 100%;
                padding: 0px 20px 0px 65px;
                position: relative;
                transition: 0.25s easy-in-out;

                &.active {
                    span {
                        border-bottom: 2px solid $color-main;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (min-height: 600px) {
    .menu {
        $root: &;

        &__list {
            li {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}
