#MainSection {
    .screen {
        position: relative;
        padding-top: 0px;

        .header {
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
        }

        img {
            width: 100%;
            max-width: none;
        }

        .image.--desktop {
            display: none;
        }
    }
}

#WhereSection {
    .text {
        max-width: 600px;
    }
}

@media (min-width: 600px) {
    #MainSection {
        .screen {
            .image.--mobile {
                display: none;
            }

            .image.--desktop {
                display: flex;
            }
        }
    }
}
