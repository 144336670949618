@import "../../vars";

.button {
    $root: &;
    background-color: transparent;
    border: 0;
    min-height: 39px;
    width: auto;
    max-width: 310px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    &:hover {
        #{$root}__background {
            path {
                fill: #D27D02;
            }
        }

        #{$root}__label {
            &:after {
                transform: translateX(3px) translateY(calc(-50%));
            }
        }
    }

    &__label {
        position: relative;
        z-index: 2;
        color: $color-main;
        text-transform: uppercase;
        font-family: $font-second;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.125rem;
        padding: 10px 38px 10px 20px;
        width: 100%;
        @media (min-width: 1024px) {
            font-size: 1rem;
        }

        &:after {
            content: '';
            display: block;
            width: 9px;
            height: 15px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            background: url("./images/arrow.svg") center center no-repeat;
            transition: .25s ease-in-out;
        }
    }

    &__background {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 100%;
        max-width: 160px;
        height: 39px;
        object-fit: cover;

        &:first-of-type {
            left: 0;
        }

        &:last-of-type {
            right: 0;
            transform: translateY(-50%) scale(-1);
        }

        rect {
            width: 100%;
            height: 39px;
        }

        path {
            fill: #D27D02;
            transition: 0.25s ease-in-out;
        }
    }
}
