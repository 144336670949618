.image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  padding: 20px;
  &--expand {
    padding: 0;
    @media (max-width: 767px) {
      margin-left: -50px;
      margin-right: -50px;
    }
  }
  img {
    width: 100%;
    display: block;
    @media (max-height: 700px) {
      max-width: 590px;
    }
  }
}
